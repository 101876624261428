import React from 'react';
import '../../assets/scss/clientSection.scss';
import CheckboxComponent from '../CheckboxComponent';
import InputComponent from '../InputComponent';

const ClientSection = ({ formData, handleChange }) => {
	return (
		<section className='clientSection'>
			<h2>Om kunden</h2>
			<div className='row'>
				<InputComponent
					label='Annonsør'
					type='text'
					name='clientName'
					value={formData.clientName}
					onChange={handleChange}
					className='customerName'
					required
				/>

				<div className='ordrenummer'>
					<InputComponent
						label='Handlekurv'
						type='number'
						name='shoppingCart'
						value={formData.shoppingCart}
						onChange={handleChange}
						className='customerHandlekurv'
						placeholder='Nummer fra handlekurv'
						required
					/>
					<InputComponent
						label='Ordrenummer'
						type='text'
						name='orderNumber'
						value={
							Array.isArray(formData.orderNumber)
								? formData.orderNumber.join(', ')
								: formData.orderNumber
						}
						onChange={handleChange}
						className='customerOrdernumber'
						required
					/>
					<p>
						Skriv inn flere ordrenummer ved å separere de med et
						komma.
						<br /> Ingen mellomrom
					</p>
				</div>

				<InputComponent
					label='ATE'
					type='number'
					name='clientAteNumber'
					value={formData.clientAteNumber}
					onChange={handleChange}
					className='customerAte'
				/>

				<div className='rammeavtale'>
					<CheckboxComponent
						label='Kunde har rammeavtale'
						type='checkbox'
						name='clientRammeavtale'
						checked={formData.clientRammeavtale}
						onChange={handleChange}
						className='customerRammeavtale'
						value='Kunde har rammeavtale'
					/>
				</div>
			</div>
		</section>
	);
};

export default ClientSection;
