const CheckboxComponent = ({
	label,
	type,
	name,
	value,
	className,
	checked,
	onChange,
	pixels,
	id,
	...rest
}) => {
	const cardClass = checked ? 'checkbox-card checked' : 'checkbox-card';

	const inputId = pixels ? id : value;
	const labelFor = pixels ? id : value;

	return (
		<div className={`checkbox-container ${className}`}>
			<div>
				<input
					type={type}
					name={name}
					id={inputId}
					checked={checked}
					onChange={onChange}
					value={value}
					className='checkbox-card-input'
					{...rest}
				/>
				<label
					className={`checkbox-card-label ${cardClass}`}
					htmlFor={labelFor}
				>
					{label}
				</label>
			</div>
			{pixels && <div className='checkbox-pixels'>{pixels}</div>}
		</div>
	);
};

export default CheckboxComponent;
