import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateComponent = ({
	label,
	name,
	className,
	handleChange,
	required,
	korr,
}) => {
	const [selectedDate, setSelectedDate] = useState(null);

	const handleDateChange = (date) => {
		setSelectedDate(date);
		handleChange({
			target: {
				name,
				value: date,
			},
		});
	};

	const days = ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'];
	const months = [
		'Januar',
		'Februar',
		'Mars',
		'April',
		'Mai',
		'Juni',
		'Juli',
		'August',
		'September',
		'Oktober',
		'November',
		'Desember',
	];

	const locale = {
		localize: {
			day: (n) => days[n],
			month: (n) => months[n],
		},
		formatLong: {
			date: () => 'dd/mm/yyyy',
		},
	};

	return (
		<div className='datePicker'>
			<label htmlFor={name}>
				{label}
				{required && <span style={{ color: 'red' }}> *</span>}
			</label>
			<DatePicker
				selected={selectedDate}
				onChange={handleDateChange}
				openToDate={new Date()}
				isClearable
				placeholderText='Velg en dato'
				name={name}
				className={className}
				id={name}
				required={required}
				dateFormat='dd/MM/yyyy'
				autoComplete='off'
				calendarStartDay={1} // Week starts on Monday
				minDate={
					korr
						? new Date(new Date().setDate(new Date().getDate() + 1))
						: new Date()
				} // Disable today if 'korr' is true
				filterDate={(date) => {
					// Only apply the logic if 'korr' is true
					if (korr) {
						const day = date.getDay();
						return day !== 0 && day !== 6; // Disable Saturdays (6) and Sundays (0)
					}
					return true; // No restrictions if 'korr' is false
				}}
				locale={locale}
			/>
		</div>
	);
};

export default DateComponent;
