export const contentPackages = [
	{
		id: 'basis',
		name: 'Basis',
		description: `
            I pakken inkluderes det et kort oppstartsmøte, tekstproduksjon med intervju av opptil to kilder via telefon eller Teams, og design av ferdig sak etter en fast mal. Bildene leverer du selv etter spesifikasjonene vi trenger for å lage innholdet best mulig. Med i prisen er det en runde korrektur og inntil tre ulike annonseinnganger til saken. Du eier ferdigmateriellet og kan derfor bruke det iht til våre retningslinjer i ettertid.
        `,
		pris: 19000,
	},
	{
		id: 'standard',
		name: 'Standard',
		description: `
            I pakken inkluderes det et oppstartsmøte, fysisk intervju og billedtakning på ønsket sted i Trondheim kommune, tekstproduksjon og cirka 8-12 ferdigredigerte bilder. Vi setter også saken opp i design med din merkevares profil og farger. 
            Med i prisen er det to runder korrektur og intervju av opptil tre kilder, som er det vi maksimalt anbefaler. 
            Du eier ferdigmateriellet og kan derfor bruke det iht til våre retningslinjer i ettertid.
        `,
		pris: 30000,
	},
	{
		id: 'avansert',
		name: 'Avansert',
		description: `
            I pakken inkluderes det et oppstartsmøte, fysisk intervju, filming og billedtakning på ønsket sted i Trondheim kommune, tekstproduksjon og cirka 8-12 ferdigredigerte bilder. Vi setter også saken opp i design med din merkevares profil og farger. 
            I tillegg leverer vi inntil to ferdigredigerte videoer på rundt ett minutt hver. Disse settes sammen med teksten og bildene i saken til en helhetlig og visuell historie. Med i prisen er det to runder korrektur og intervju av opptil tre kilder, som er det vi maksimalt anbefaler. 
            Du eier ferdigmateriellet og kan derfor bruke det iht til våre retningslinjer i ettertid.
        `,
		pris: 40000,
	},
	{
		id: 'resalgspakke',
		name: 'Resalgspakker',
		description: `
			Gjenbruk av innholdsproduksjon med evnt. tilpasning av innhold
		`,
		from: 6400,
	},
];

export const relansering = [
	{
		id: 'relanseringfoto',
		name: 'Foto',
		from: 5000,
	},
	{
		id: 'relanseringvideo',
		name: 'Video',
		from: 5000,
	},
	{
		id: 'relanseringrapportinnsikt',
		name: 'Rapport med innsikt',
		pris: 4800,
	},
];

export const additionalPackages = [
	{
		id: 'foto',
		name: 'Foto',
		from: 5000,
	},
	{
		id: 'video',
		name: 'Video',
		from: 5000,
	},
	{
		id: 'driving',
		name: 'Tillegg kjøring',
		from: 800,
		sub: [
			{
				id: 'trondheim',
				name: 'Trondheim + 30km',
				two: 'Inkl',
				one: 'Inkl',
			},
			{
				id: 'stjordal',
				name: 'Stjørdal',
				two: 1600,
				one: 800,
			},
			{
				id: 'meraker',
				name: 'Meråker',
				two: 2500,
				one: 1600,
			},
			{
				id: 'frosta',
				name: 'Frosta',
				two: 2500,
				one: 1600,
			},
			{
				id: 'levver',
				name: 'Levanger/Verdal',
				two: 2500,
				one: 1600,
			},
			{
				id: 'steinkjer',
				name: 'Steinkjer',
				two: 3500,
				one: 2250,
			},
			{
				id: 'namsos',
				name: 'Namsos',
				two: 5000,
				one: 3750,
			},
			{
				id: 'orkanger',
				name: 'Orkanger',
				two: 1600,
				one: 800,
			},
			{
				id: 'storen',
				name: 'Støren',
				two: 1800,
				one: 1000,
			},
			{
				id: 'berkak',
				name: 'Berkåk',
				two: 2500,
				one: 1600,
			},
			{
				id: 'oppdal',
				name: 'Oppdal',
				two: 3500,
				one: 2250,
			},
			{
				id: 'brekstad',
				name: 'Brekstad',
				two: 4500,
				one: 3200,
			},
			{
				id: 'hitfroy',
				name: 'Hitra/Frøya',
				two: 4500,
				one: 3200,
			},
		],
	},
	{
		id: 'skreddersom',
		name: 'Skreddersøm VEV',
		pris: 9600,
	},
	{
		id: 'vevmedvideo',
		name: 'VEV med video',
		pris: 8000,
		description: 'Forutsetter ferdig redigert video levert fra kunde',
	},
	{
		id: 'leadskjemavev',
		name: 'Leadskjema VEV',
		pris: 7500,
		description: `
            Skjema blir utarbeidet med hensyn til gjeldende GDPR med retningslinjer fra Polaris Media.
            Polaris Media og kunde signerer databehandleravtale satt opp i regi av Polaris Media`,
	},
	{
		id: 'produktkarusellvev',
		name: 'Produktkarusell VEV',
		from: 4800,
	},
	{
		id: 'rapportinnsikt',
		name: 'Rapport med innsikt',
		pris: 4800,
	},
	{
		id: 'tilpasningsomevideo',
		name: 'Tilpasning SoMe video',
		pris: 6400,
	},
	{
		id: 'somepakkeliten',
		name: 'SoMe pakke Liten',
		pris: 11900,
		isSomePackage: true,
		sub: [
			{
				id: 'kampanjeoppsett',
				name: 'Kampanjeoppsett SoMe',
				pris: 3200,
				description:
					'Én mediekanal - 10 dager + 40km. Basert på Content materiell',
			},
			{
				id: 'mediespending',
				name: 'Mediespending',
				pris: 5500,
				description: 'Pr. kampanje inkl. 10% adm. gebyr',
			},
			{
				id: 'kampanjeadmin',
				name: 'Kampanjeadmin',
				pris: 3200,
				description: 'Koordinering + Rapport',
			},
		],
	},
	{
		id: 'somepakkestor',
		name: 'SoMe pakke Stor',
		pris: 19000,
		isSomePackage: true,
		sub: [
			{
				id: 'kampanjeoppsett',
				name: 'Kampanjeoppsett SoMe',
				pris: 4800,
				description:
					'To mediekanal - 10 dager + 40km. Basert på Content materiell',
			},
			{
				id: 'mediespending',
				name: 'Mediespending',
				pris: 11000,
				description: 'Pr. kampanje inkl. 10% adm. gebyr',
			},
			{
				id: 'kampanjeadmin',
				name: 'Kampanjeadmin',
				pris: 3200,
				description: 'Koordinering + Rapport',
			},
		],
	},
	{
		id: 'sokeordanalyse',
		name: 'Søkeordanalyse',
		pris: 10000,
		description: `<b>Analyse av nettstedet og hvordan innholdet kan optimaliseres for bedre synlighet i søkemotorer (f.eks. Google)</b><br />. 
        Analysen dekker forbedringer ang. rangering og anbefaler hvilken type innhold som bør lages for å støtte de riktige type rangeringer for kunden. Analysen benyttes av Studio dersom innhold er bestilt gjennom Adresseavisen. <br />
        Metadata for hovedsidene + søkeord og SEO for <b>3 innholdssaker</b>
        `,
	},
];
